.menu-item {
    margin: 45px 0px;
}

.menu-item a {
    display: block;
    text-align: center;
    padding: 40px 10px;
    border: 5px solid #13AE9C;
    border-radius: 20px;
    font-size: 22px !important;
    font-weight: bold;
    text-decoration: none;
    color: #13AE9C;
}

.menu-item a:hover {
    background-color: #f9f96d;
}

.menu-item-notification {
    display: block;
    text-align: center;
    margin: 40px 20px;
    padding: 20px 10px;
    border: 5px solid #13AE9C;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #13AE9C;
    width: 95% !important;
}

.title-store {
    margin-bottom: 55px !important;
    text-align: left;
    color: black;
}

.breadcrumb, ul > li > a {
    text-decoration: none;
}
.mh-171 {
    min-height: calc(100vh - 171px) !important;
}
.color-13AE9C {
    color: #13AE9C !important;
}
.float-inline-end {
    float: inline-end !important;
}
.h-support {
    overflow: hidden;
    height: 10vh;
}