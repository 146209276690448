
h2 {
    text-align: center;
    color: #727272;
    font-size: 35px;
    font-weight: bold;
}

.pageTop {
    bottom: 90px;
    right: 30px;
}

.pageTop:hover {
    cursor: pointer;
}

.contactArea01 {
    background: #eee;
    display: none;
    position: relative;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
}

.contactContent01 {
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


.confirm {
    padding-top: 10px;
}

.pageTop img {
    width: 50px;
    height: 50px;
    vertical-align: bottom;
}

.btn01 {
    width: 300px;
    background-color: #fafafa;
}

/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* body {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    background: #fff;
    line-height: 1.5;
} */

@media screen and (max-width: 767px) {
    body.fixed {
        position: fixed;
        right: 0;
        left: 0;
    }
}

a {
    color: #333;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

/* a:hover {
    opacity: 0.8;
    color: #13ae9c !important;
} */

img {
    max-width: 100%;
}

.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:after {
    content: "";
    clear: both;
    display: block;
}

.center {
    text-align: center;
}

.textRight {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .pcOnly {
        display: none;
    }
}

@media print,
screen and (min-width: 768px) {
    .spOnly {
        display: none;
    }
}

@media print,
screen and (min-width: 769px) {
    .allLink:not(.allLinkNone) {
        cursor: pointer;
        -webkit-transition: 0.3s ease-in-out all;
        transition: 0.3s ease-in-out all;
    }

    .allLink:not(.allLinkNone):hover {
        opacity: 0.8;
    }

    .allLink:not(.allLinkNone) a:hover {
        opacity: 1;
    }
}

.displayNone {
    display: none;
}

.breadcrumb {
    font-size: 13px;
    margin-bottom: 50px;
    background-color: #fff !important;
}

@media all and (-ms-high-contrast: none) {
    .breadcrumb li:not(:first-child) a {
        line-height: 1;
    }
}

.breadcrumb li:not(:last-child) {
    margin-right: 10px;
    /* padding-right: 15px; */
    position: relative;
}

.contact-body .breadcrumb li:not(:last-child):after {
    content: "";
    width: 6px;
    height: 6px;
    margin: auto 0;
    border-top: 1px solid #333;
    border-right: 1px solid #333;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

input[type=submit] {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

input[type=submit]:hover {
    opacity: 0.8;
}

input[type=text],
input[type=email],
input[type=tel] {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 14px;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

textarea {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 14px;
    width: 100%;
    height: 120px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: auto;
    resize: none;
}

.checkbox input[type=checkbox] {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
}

.checkbox input+span::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #505050;
    margin-right: 5px;
    display: inline-block;
    vertical-align: sub;
}

.checkbox input:checked+span::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%2313AE9C%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 0 1px;
}

.checkbox label:hover {
    cursor: pointer;
}

.checkbox span {
    color: #505050;
    font-size: 16px;
    font-weight: bold;
    line-height: 0;
}

.radio {
    padding-left: 22px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.radio:before,
.radio:after {
    content: "";
    position: absolute;
}

.radio:before {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 50%;
    z-index: 3;
    top: 7px;
    left: 0;
}

.radio:after {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #5876b6;
    z-index: 1;
    top: 12px;
    left: 5px;
}

.radio input[type=radio] {
    width: 20px;
    height: 20px;
    margin: 0;
    -webkit-box-shadow: 20px -1px #fff;
    box-shadow: 20px -1px #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 2;
    position: absolute;
    top: 7px;
    left: -23px;
}

.radio input[type=radio]:checked {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.radio input[type=radio]:focus {
    opacity: 0.2;
    -webkit-box-shadow: 20px -1px #fff;
    box-shadow: 20px -1px #fff;
}

.selectBox01 {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    position: relative;
}

.selectBox01:after {
    content: "";
    width: 4px;
    height: 4px;
    margin: auto 0;
    border: 4px solid transparent;
    border-top: 4px solid #333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 18px;
    right: 15px;
}

.selectBox01 select {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    background: transparent;
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
}

@media all and (-ms-high-contrast: none) {
    .selectBox01 select {
        width: 100% !important;
    }
}

.selectBox01 select:not(:target) {
    width: 130% \9;
}

.selectBox01 select::-ms-expand {
    display: none;
}

.headerTop {
    margin-top: -86px;
    padding-top: 86px;
}

.btn01 {
    width: 300px;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .btn01 {
        width: 275px;
    }
}

.orangeBtn01 a,
.orangeBtn01 a:disabled {
    background: #ffb950;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #ffb950), to(#ffd87f));
    background: linear-gradient(to right, #ffb950 30%, #ffd87f 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb950", endColorstr="#ffd87f", GradientType=1);
    /* IE6-9 */
}

.orangeBtn01 a:after {
    background: #f07d3c;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #f07d3c), to(#ffb950));
    background: linear-gradient(to right, #f07d3c 30%, #ffb950 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f07d3c", endColorstr="#ffb950", GradientType=1);
    /* IE6-9 */
}

.orangeBtn02 a,
.orangeBtn02 a:disabled {
    background: #ffb950;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #ffb950), to(#ffd87f));
    background: linear-gradient(to right, #ffb950 30%, #ffd87f 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb950", endColorstr="#ffd87f", GradientType=1);
    /* IE6-9 */
}

.orangeBtn02 a:after {
    background: #ffb950;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #ffb950), to(#ffd87f));
    background: linear-gradient(to right, #ffb950 30%, #ffd87f 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb950", endColorstr="#ffd87f", GradientType=1);
    /* IE6-9 */
}

.whiteBtn01 {
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

.whiteBtn01:hover {
    cursor: pointer;
    opacity: 0.8 !important;
}

.scrollArea01 {
    overflow-y: auto;
    scrollbar-color: #ddd #f4f4f4;
    scrollbar-width: thin;
    scrollbar-base-color: #ddd;
    scrollbar-track-color: #f4f4f4;
    scrollbar-arrow-color: #f4f4f4;
    scrollbar-shadow-color: #ddd;
}

.scrollArea01::-webkit-scrollbar {
    width: 10px;
    display: none;
}

.scrollArea01::-webkit-scrollbar-track {
    background: #f4f4f4;
}

.scrollArea01::-webkit-scrollbar-thumb {
    background: #ddd;
}

.modalOpen,
.modalClose {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

.modalOpen:hover,
.modalClose:hover {
    opacity: 0.8;
}

.modalOverlay {
    background: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modalClose {
    position: absolute;
    top: -35px;
    right: 0;
}

.modalArea01 {
    margin: auto;
    z-index: 7;
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .modalArea01 {
        width: 80%;
    }
}

.modalArea01 img {
    max-width: 710px;
    max-height: 600px;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .modalArea01 img {
        max-width: 100%;
        max-height: 340px;
    }
}

.verticalTop {
    vertical-align: top;
}

.fadeinUnder {
    opacity: 0;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinRight {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinLeft {
    opacity: 0;
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinUnder.scrollin,
.fadeinRight.scrollin,
.fadeinLeft.scrollin {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.scrollHeader01 {
    margin-top: -75px;
    padding-top: 125px;
}

@media screen and (max-width: 767px) {
    .scrollHeader01 {
        margin-top: -45px;
        padding-top: 75px;
    }
}

.mainContent .heading01 {
    position: relative;
    margin-bottom: 65px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 {
        margin-bottom: 35px;
    }
}

.mainContent .heading01:before {
    content: "";
    width: 68vh;
    height: 2px;
    background-color: #dcdcdc;
    position: absolute;
    left: -71vh;
    top: 18px;
}

@media screen and (max-width: 1100px) {
    .mainContent .heading01:before {
        display: none;
    }
}

.mainContent .heading01:after {
    content: "";
    width: 8%;
    height: 2px;
    background-color: #96d6cf;
    position: absolute;
    top: 18px;
    left: -11%;
}

@media screen and (max-width: 1100px) {
    .mainContent .heading01:after {
        width: 20px;
        left: -30px;
    }
}

@media screen and (max-width: 767px) {
    .mainContent .heading01:after {
        width: 50px;
        left: -60px;
        top: 12px;
    }
}

.mainContent .heading01 .paint-in {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.mainContent .heading01 .paint-in__content {
    position: absolute;
    top: 0;
    left: -200%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading01 .paint-in__content img {
    height: 36px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .paint-in__content img {
        height: 21.5px;
    }
}

.mainContent .heading01 .paint-in__smear {
    color: #fff;
    font-size: 50px;
    line-height: 0.8;
    margin-left: 20px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .paint-in__smear {
        font-size: 30px;
    }
}

.mainContent .heading01 .paint-in__smear:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #13ae9c;
    position: absolute;
    top: 0;
    left: -120%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading01 .sub {
    color: #9c9c9c;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    display: block;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .sub {
        font-size: 14px;
    }
}

.mainContent .heading01 .sub .paint-in__smear {
    color: transparent;
    font-size: 20px;
    font-weight: bold;
}

.mainContent .heading01.animation .paint-in__content {
    left: 0;
}

.mainContent .heading01.animation .paint-in__smear:before {
    left: 100%;
}

.mainContent .heading02 {
    color: #13ae9c;
    font-size: 30px;
    font-weight: bold;
    display: block;
    position: relative;
}

@media screen and (max-width: 767px) {
    .mainContent .heading02 {
        font-size: 20px;
    }
}

.mainContent .heading02 .titWrap01 {
    position: relative;
}

.mainContent .heading02 .titWrap01:after {
    content: "";
    width: 80px;
    height: 2px;
    margin: auto;
    background-color: #96d6cf;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading02 .titWrap01:after {
        width: 68px;
        right: -80px;
    }
}

.mainContent .heading02 .titWrap01 .paint-in {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.mainContent .heading02 .paint-in__content {
    position: absolute;
    top: 0;
    left: -200%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading02 .paint-in__smear {
    color: transparent;
}

.mainContent .heading02 .paint-in__smear:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #13ae9c;
    position: absolute;
    top: 0;
    left: -120%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading02.animation .paint-in__content {
    left: 0;
}

.mainContent .heading02.animation .paint-in__smear:before {
    left: 100%;
}

.bottomArea01 {
    width: 100%;
    height: 420px;
    background: url(".") no-repeat center right;
    background-size: cover;
}

@media screen and (max-width: 767px) {
    .bottomArea01 {
        height: 185px;
    }
}

.pageTop {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9997;
    display: none;
}

@media screen and (max-width: 767px) {
    .pageTop {
        width: 35px;
        height: 35px;
        bottom: 15px;
        right: 15px;
    }
}

.pageTop.bottom {
    position: absolute;
}

.pageTop img {
    width: 50px;
    height: 50px;
    vertical-align: bottom;
}

@media screen and (max-width: 767px) {
    .pageTop img {
        width: 35px;
        height: 35px;
    }
}

.header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.header.scroll {
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.header.scroll .headerContent01 {
    padding: 10px 0;
    height: 55px;
}

@media screen and (max-width: 767px) {
    .header.scroll .headerContent01 {
        height: 45px;
    }
}

.header.scroll .headerContent01 a {
    font-size: 14px;
    padding-bottom: 4px;
}

@media screen and (max-width: 1000px) {
    .header.scroll .headerContent01 a {
        font-size: 13px;
    }
}

.header.scroll .header-logo {
    width: 122px;
}

@media screen and (max-width: 900px) {
    .header.scroll .header-logo {
        width: 84px;
    }
}

.header.scroll .pcheaderList01 li:not(.login01) a {
    padding-bottom: 4px;
}

@media screen and (max-width: 767px) {
    .header .container {
        width: 94% !important;
    }
}

.header .headerContent01 {
    padding: 30px 0;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

@media screen and (max-width: 767px) {
    .header .headerContent01 {
        padding: 10px 0;
        height: 45px;
    }
}

.header .header-logo {
    width: 152px;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

@media screen and (max-width: 1000px) {
    .header .header-logo {
        width: 92px;
    }
}

@media screen and (max-width: 767px) {
    .header .header-logo {
        width: 76px;
    }
}

.header .header-logo img {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    vertical-align: middle;
}

.pcheaderMenu01 .pcheaderList01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pcheaderMenu01 .pcheaderList01 li:not(:last-child) {
    margin-right: 20px;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 li:not(:last-child) {
        margin-right: 12px;
    }
}

.pcheaderMenu01 .pcheaderList01 .contact01 a {
    padding-left: 30px;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 .contact01 a {
        padding-left: 20px;
    }
}

.pcheaderMenu01 .pcheaderList01 .contact01 a:before {
    content: "";
    width: 22px;
    height: 14px;
    background: url("") no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 0;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 .contact01 a:before {
        width: 15px;
        height: 10px;
    }
}

.pcheaderMenu01 .pcheaderList01 a {
    color: #505050;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 8px;
    display: block;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .pcheaderMenu01 .pcheaderList01 a {
        font-size: 13px;
        padding-bottom: 4px;
    }
}

.pcheaderMenu01 .pcheaderList01 a:after {
    content: "";
    width: 0;
    height: 2px;
    background-color: #13ae9c;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: 0.25s;
    transition: 0.25s;
}

.pcheaderMenu01 .pcheaderList01 a:hover {
    opacity: 1;
}

.pcheaderMenu01 .pcheaderList01 a:hover:after {
    width: 100%;
}

.pcheaderMenu01 .pcheaderList01 a.now {
    opacity: 1;
}

.pcheaderMenu01 .pcheaderList01 a.now:after {
    width: 100%;
}

.pcheaderMenu01 .pcheaderList01 .login01 {
    margin-top: -4px;
}

.spBtn01 {
    -webkit-transition: 1s;
    transition: 1s;
}

@media print,
screen and (min-width: 768px) {
    .spBtn01 {
        display: none !important;
    }
}

.spBtn01 p {
    width: 22px;
    height: 18px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    z-index: 9999;
    position: fixed;
    top: 13px;
    right: 15px;
}

.spBtn01 p.active span {
    background: #fff;
}

.spBtn01 p.active span:first-child {
    -webkit-transform: translateY(8px) rotate(-45deg);
    transform: translateY(8px) rotate(-45deg);
}

.spBtn01 p.active span:nth-child(2) {
    opacity: 0;
}

.spBtn01 p.active span:last-child {
    -webkit-transform: translateY(-8px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
}

.spBtn01 span {
    width: 22px;
    height: 2px;
    margin: 0 auto;
    background: #14af9c;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
}

.spBtn01 span:first-child {
    top: 0;
}

.spBtn01 span:nth-child(2) {
    top: 8px;
}

.spBtn01 span:nth-child(3) {
    bottom: 0;
}

.spBtn01.active {
    width: 100%;
    height: 45px;
    background: rgba(19, 174, 156, 0.85);
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.globalMenuArea01 {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 45px;
    padding-bottom: 45px;
    background: rgba(19, 174, 156, 0.85);
    border: 0;
    overflow-y: scroll;
    z-index: 9998;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -ms-overflow-style: none;
}

.globalMenuArea01::-webkit-scrollbar {
    display: none;
}

@media print,
screen and (min-width: 768px) {
    .globalMenuArea01 {
        display: none !important;
    }
}

.globalMenuArea01 .menuContent01 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.globalMenuArea01 .menuContent01.service {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.globalMenuArea01 .menuContent01.service .spMenu a {
    font-weight: bold;
    padding: 15px;
}

.globalMenuArea01 .spMenu a {
    padding: 20px;
}

.globalMenuArea01 a {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    display: block;
    position: relative;
}

.globalMenuArea01 .spLoginMenuArea01 {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px;
}

.globalMenuArea01 .spLoginTitle {
    color: #13ae9c;
    font-size: 16px;
    padding-bottom: 6px;
    border-bottom: 2px solid #13ae9c;
}

.globalMenuArea01 .spLoginTitle img {
    margin-right: 5px;
}

.globalMenuArea01 .spLoginMenu {
    padding: 25px 0 10px;
}

.globalMenuArea01 .spLoginMenu li:first-child {
    margin-bottom: 20px;
}

.globalMenuArea01 .spLoginMenu a {
    color: #13ae9c;
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-weight: bold;
}

.globalMenuArea01 .contact01 a span {
    padding-left: 30px;
    position: relative;
}

.globalMenuArea01 .contact01 a span:after {
    content: "";
    width: 22px;
    height: 14px;
    margin: auto;
    background: url("") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.globalMenuArea01 .login01 {
    margin-top: -4px;
}

.globalMenuArea01 .login01 a span {
    background: url() no-repeat center left;
    background-size: 16px 16px;
    padding: 0 0 0 24px;
}

.globalMenuArea01 .dropBtn01 {
    color: #fff;
    padding: 15px;
    position: relative;
}

.globalMenuArea01 .dropBtn01:before,
.globalMenuArea01 .dropBtn01:after {
    content: "";
    position: absolute;
}

.globalMenuArea01 .dropBtn01:before {
    width: 14px;
    height: 1px;
    margin: auto 0;
    background: #fff;
    border: 0;
    -webkit-transform: none;
    transform: none;
    display: block;
    top: 0;
    right: 15px;
    bottom: 0;
    left: auto;
}

.globalMenuArea01 .dropBtn01:after {
    width: 1px;
    height: 14px;
    background: #fff;
    border: 0;
    -webkit-transform: none;
    transform: none;
    right: 22px;
}

.globalMenuArea01 .dropBtn01.active:after {
    display: none;
}

.globalMenuArea01 .drop01 ul {
    display: none;
}

.globalMenuArea01 .drop01 a {
    padding-left: 25px;
}

.loginMenuArea01 {
    position: fixed;
    background-color: #13ae9c;
    width: 100%;
    padding: 20px 0;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 10000;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    display: none;
}

@media screen and (max-width: 767px) {
    .loginMenuArea01 {
        top: 45px;
    }
}

.loginMenuArea01.scroll {
    top: 55px;
}

@media screen and (max-width: 767px) {
    .loginMenuArea01.scroll {
        top: 45px;
    }
}

.loginMenuArea01 .container {
    position: relative;
}

.loginMenuArea01 .container::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent #13ae9c transparent;
    display: block;
    position: absolute;
    top: -28px;
    right: 53px;
}

.loginMenu01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginMenu01 li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginMenu01 li a {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.loginMenu01 li img {
    margin-right: 7px;
}

.loginMenu01 li:first-child::after {
    content: "";
    width: 1px;
    height: 50px;
    margin: 0 40px;
    background-color: #fff;
    display: block;
}

.footer-contact {
    background-color: #fff;
    position: relative;
    bottom: -72px;
    z-index: 9999;
}
.footer-contact .menuArea01 {
    padding: 25px 0 50px;
    background-color: #13AE9C;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuArea01 {
        padding: 25px 0;
    }
}

.footer-contact .menuContent01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-contact .menuContent01 .menuList01 {
    margin-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .menuList01 {
        margin-bottom: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.footer-contact .menuContent01 .menuList01 li {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.footer-contact .menuContent01 .menuList01 li:not(:last-child) {
    margin-right: 35px;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .menuList01 li:not(:last-child) {
        margin: 0;
    }
}

.footer-contact .menuContent01 .menuList01 li:last-child a {
    padding-left: 30px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .menuList01 li:last-child a {
        padding-left: 17px;
    }
}

.footer-contact .menuContent01 .menuList01 li:last-child a:before {
    content: "";
    width: 22px;
    height: 14px;
    margin: auto;
    background: url("") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .menuList01 li:last-child a:before {
        width: 13px;
        height: 8px;
    }
}

.footer-contact .menuContent01 .menuList01 li a {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .menuList01 li a {
        font-size: 12px;
        padding: 8px;
    }
}

.footer-contact .menuContent01 .footer-logo {
    width: 71px;
}

@media screen and (max-width: 767px) {
    .footer-contact .menuContent01 .footer-logo {
        width: 44px;
    }
}

.footer-contact .menuContent01 .footer-logo img {
    vertical-align: bottom;
}

.footerArea01 {
    padding: 15px 0 20px;
}

@media screen and (max-width: 767px) {
    .footerArea01 {
        padding: 15px;
    }
}

.footerArea01 .footerContent01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerContent01 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.footerArea01 .footerBox01 {
    font-size: 13px;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerBox01 {
        font-size: 11px;
    }
}

.footerArea01 .footerBox01.box01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerBox01.box01 {
        margin-bottom: 15px;
    }
}

.footerArea01 .footerBox01.box01 p {
    display: block;
}
.footerArea01 .footerBox01.box01 p a{
    text-decoration: none;
    color: #333;
}

.footerArea01 .footerBox01.box01 p:first-child {
    margin-right: 25px;
}

.footerLogo01 {
    width: 71px;
    height: 101px;
}

@media screen and (max-width: 767px) {
    .footerLogo01 {
        width: 44px;
        height: 61px;
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    /* font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif; */
    font-size: 14px;
    font-weight: 500;
    color: #333;
    background: #fff;
    line-height: 1.5;
}

@media screen and (max-width: 767px) {
    body.fixed {
        position: fixed;
        right: 0;
        left: 0;
    }
}

a {
    color: #333;
    text-decoration: none;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

a:hover {
    opacity: 0.8;
}

img {
    max-width: 100%;
}

/* .container {
    max-width: 1000px;
    width: 94%;
    margin: 0 auto;
} */

/* @media screen and (max-width: 767px) {
    .container {
        width: 84%;
    }
} */

.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:after {
    content: "";
    clear: both;
    display: block;
}

.center {
    text-align: center;
}

.textRight {
    text-align: right;
}

@media screen and (max-width: 767px) {
    .pcOnly {
        display: none;
    }
}

@media print,
screen and (min-width: 768px) {
    .spOnly {
        display: none;
    }
}

@media print,
screen and (min-width: 769px) {
    .allLink:not(.allLinkNone) {
        cursor: pointer;
        -webkit-transition: 0.3s ease-in-out all;
        transition: 0.3s ease-in-out all;
    }

    .allLink:not(.allLinkNone):hover {
        opacity: 0.8;
    }

    .allLink:not(.allLinkNone) a:hover {
        opacity: 1;
    }
}

.displayNone {
    display: none;
}

.breadcrumb {
    font-size: 13px;
    margin-bottom: 50px;
}

@media all and (-ms-high-contrast: none) {
    .breadcrumb li:not(:first-child) a {
        line-height: 1;
    }
}

.breadcrumb li:not(:last-child) {
    margin-right: 10px;
    /* padding-right: 15px; */
    position: relative;
}

.contact-body .breadcrumb li:not(:last-child):after {
    content: "";
    width: 6px;
    height: 6px;
    margin: auto 0;
    border-top: 1px solid #333;
    border-right: 1px solid #333;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

input[type=submit] {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

input[type=submit]:hover {
    opacity: 0.8;
}

input[type=text],
input[type=email],
input[type=tel] {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 14px;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

textarea {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 14px;
    width: 100%;
    height: 120px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    overflow: auto;
    resize: none;
}

.checkbox input[type=checkbox] {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
}

.checkbox input+span::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #505050;
    margin-right: 5px;
    display: inline-block;
    vertical-align: sub;
}

.checkbox input:checked+span::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%2313AE9C%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: 0 1px;
}

.checkbox label:hover {
    cursor: pointer;
}

.checkbox span {
    color: #505050;
    font-size: 16px;
    font-weight: bold;
    line-height: 0;
}

.radio {
    padding-left: 22px;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.radio:before,
.radio:after {
    content: "";
    position: absolute;
}

.radio:before {
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 50%;
    z-index: 3;
    top: 7px;
    left: 0;
}

.radio:after {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #5876b6;
    z-index: 1;
    top: 12px;
    left: 5px;
}

.radio input[type=radio] {
    width: 20px;
    height: 20px;
    margin: 0;
    -webkit-box-shadow: 20px -1px #fff;
    box-shadow: 20px -1px #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 2;
    position: absolute;
    top: 7px;
    left: -23px;
}

.radio input[type=radio]:checked {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.radio input[type=radio]:focus {
    opacity: 0.2;
    -webkit-box-shadow: 20px -1px #fff;
    box-shadow: 20px -1px #fff;
}

.selectBox01 {
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    position: relative;
}

.selectBox01:after {
    content: "";
    width: 4px;
    height: 4px;
    margin: auto 0;
    border: 4px solid transparent;
    border-top: 4px solid #333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 18px;
    right: 15px;
}

.selectBox01 select {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    width: 100%;
    height: 40px;
    padding-left: 15px;
    background: transparent;
    border: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    outline: none;
}

@media all and (-ms-high-contrast: none) {
    .selectBox01 select {
        width: 100% !important;
    }
}

.selectBox01 select:not(:target) {
    width: 130% \9;
}

.selectBox01 select::-ms-expand {
    display: none;
}

.headerTop {
    margin-top: -86px;
    padding-top: 86px;
}

.btn01 {
    width: 300px;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .btn01 {
        width: 275px;
    }
}

.orangeBtn01 a {
    background: #ffb950;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #ffb950), to(#ffd87f));
    background: linear-gradient(to right, #ffb950 30%, #ffd87f 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb950", endColorstr="#ffd87f", GradientType=1);
    /* IE6-9 */
}

.orangeBtn01 a:after {
    background: #f07d3c;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, #f07d3c), to(#ffb950));
    background: linear-gradient(to right, #f07d3c 30%, #ffb950 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f07d3c", endColorstr="#ffb950", GradientType=1);
    /* IE6-9 */
}

.whiteBtn01 {
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

.whiteBtn01:hover {
    cursor: pointer;
    opacity: 0.8 !important;
}

.scrollArea01 {
    overflow-y: auto;
    scrollbar-color: #ddd #f4f4f4;
    scrollbar-width: thin;
    scrollbar-base-color: #ddd;
    scrollbar-track-color: #f4f4f4;
    scrollbar-arrow-color: #f4f4f4;
    scrollbar-shadow-color: #ddd;
}

.scrollArea01::-webkit-scrollbar {
    width: 10px;
}

.scrollArea01::-webkit-scrollbar-track {
    background: #f4f4f4;
}

.scrollArea01::-webkit-scrollbar-thumb {
    background: #ddd;
}

.modalOpen,
.modalClose {
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
}

.modalOpen:hover,
.modalClose:hover {
    opacity: 0.8;
}

.modalOverlay {
    background: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    z-index: 4;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.modalClose {
    position: absolute;
    top: -35px;
    right: 0;
}

.modalArea01 {
    margin: auto;
    z-index: 7;
    position: fixed;
    top: 0;
    left: 0;
}

@media screen and (max-width: 768px) {
    .modalArea01 {
        width: 80%;
    }
}

.modalArea01 img {
    max-width: 710px;
    max-height: 600px;
    margin: auto;
}

@media screen and (max-width: 768px) {
    .modalArea01 img {
        max-width: 100%;
        max-height: 340px;
    }
}

.verticalTop {
    vertical-align: top;
}

.fadeinUnder {
    opacity: 0;
    -webkit-transform: translate(0, 50px);
    transform: translate(0, 50px);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinRight {
    opacity: 0;
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinLeft {
    opacity: 0;
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0);
    -webkit-transition: all 1000ms;
    transition: all 1000ms;
}

.fadeinUnder.scrollin,
.fadeinRight.scrollin,
.fadeinLeft.scrollin {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.scrollHeader01 {
    margin-top: -75px;
    padding-top: 125px;
}

@media screen and (max-width: 767px) {
    .scrollHeader01 {
        margin-top: -45px;
        padding-top: 75px;
    }
}

.mainContent .heading01 {
    position: relative;
    margin-bottom: 65px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 {
        margin-bottom: 35px;
    }
}

.mainContent .heading01:before {
    content: "";
    width: 68vh;
    height: 2px;
    background-color: #dcdcdc;
    position: absolute;
    left: -71vh;
    top: 18px;
}

@media screen and (max-width: 1100px) {
    .mainContent .heading01:before {
        display: none;
    }
}

.mainContent .heading01:after {
    content: "";
    width: 8%;
    height: 2px;
    background-color: #96d6cf;
    position: absolute;
    top: 18px;
    left: -11%;
}

@media screen and (max-width: 1100px) {
    .mainContent .heading01:after {
        width: 20px;
        left: -30px;
    }
}

@media screen and (max-width: 767px) {
    .mainContent .heading01:after {
        width: 50px;
        left: -60px;
        top: 12px;
    }
}

.mainContent .heading01 .paint-in {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.mainContent .heading01 .paint-in__content {
    position: absolute;
    top: 0;
    left: -200%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading01 .paint-in__content img {
    height: 36px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .paint-in__content img {
        height: 21.5px;
    }
}

.mainContent .heading01 .paint-in__smear {
    color: #fff;
    font-size: 50px;
    line-height: 0.8;
    margin-left: 20px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .paint-in__smear {
        font-size: 30px;
    }
}

.mainContent .heading01 .paint-in__smear:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #13ae9c;
    position: absolute;
    top: 0;
    left: -120%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading01 .sub {
    color: #9c9c9c;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    display: block;
}

@media screen and (max-width: 767px) {
    .mainContent .heading01 .sub {
        font-size: 14px;
    }
}

.mainContent .heading01 .sub .paint-in__smear {
    color: transparent;
    font-size: 20px;
    font-weight: bold;
}

.mainContent .heading01.animation .paint-in__content {
    left: 0;
}

.mainContent .heading01.animation .paint-in__smear:before {
    left: 100%;
}

.mainContent .heading02 {
    color: #13ae9c;
    font-size: 30px;
    font-weight: bold;
    display: block;
    position: relative;
}

@media screen and (max-width: 767px) {
    .mainContent .heading02 {
        font-size: 20px;
    }
}

.mainContent .heading02 .titWrap01 {
    position: relative;
}

.mainContent .heading02 .titWrap01:after {
    content: "";
    width: 80px;
    height: 2px;
    margin: auto;
    background-color: #96d6cf;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100px;
}

@media screen and (max-width: 767px) {
    .mainContent .heading02 .titWrap01:after {
        width: 68px;
        right: -80px;
    }
}

.mainContent .heading02 .titWrap01 .paint-in {
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}

.mainContent .heading02 .paint-in__content {
    position: absolute;
    top: 0;
    left: -200%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading02 .paint-in__smear {
    color: transparent;
}

.mainContent .heading02 .paint-in__smear:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #13ae9c;
    position: absolute;
    top: 0;
    left: -120%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.mainContent .heading02.animation .paint-in__content {
    left: 0;
}

.mainContent .heading02.animation .paint-in__smear:before {
    left: 100%;
}

.bottomArea01 {
    width: 100%;
    height: 420px;
    background: url(".") no-repeat center right;
    background-size: cover;
}

@media screen and (max-width: 767px) {
    .bottomArea01 {
        height: 185px;
    }
}

.pageTop {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9997;
    display: none;
}

@media screen and (max-width: 767px) {
    .pageTop {
        width: 35px;
        height: 35px;
        bottom: 15px;
        right: 15px;
    }
}

.pageTop.bottom {
    position: absolute;
}

.pageTop img {
    width: 50px;
    height: 50px;
    vertical-align: bottom;
}

@media screen and (max-width: 767px) {
    .pageTop img {
        width: 35px;
        height: 35px;
    }
}

.header {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.header.scroll {
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.header.scroll .headerContent01 {
    padding: 10px 0;
    height: 55px;
}

@media screen and (max-width: 767px) {
    .header.scroll .headerContent01 {
        height: 45px;
    }
}

.header.scroll .headerContent01 a {
    font-size: 14px;
    padding-bottom: 4px;
}

@media screen and (max-width: 1000px) {
    .header.scroll .headerContent01 a {
        font-size: 13px;
    }
}

.header.scroll .header-logo {
    width: 122px;
}

@media screen and (max-width: 900px) {
    .header.scroll .header-logo {
        width: 84px;
    }
}

.header.scroll .pcheaderList01 li:not(.login01) a {
    padding-bottom: 4px;
}

/* @media screen and (max-width: 767px) {
    .header .container {
        width: 94% !important;
    }
} */

.header .headerContent01 {
    padding: 30px 0;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

@media screen and (max-width: 767px) {
    .header .headerContent01 {
        padding: 10px 0;
        height: 45px;
    }
}

.header .header-logo {
    width: 152px;
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

@media screen and (max-width: 1000px) {
    .header .header-logo {
        width: 92px;
    }
}

@media screen and (max-width: 767px) {
    .header .header-logo {
        width: 76px;
    }
}

.header .header-logo img {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    vertical-align: middle;
}

.pcheaderMenu01 .pcheaderList01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pcheaderMenu01 .pcheaderList01 li:not(:last-child) {
    margin-right: 20px;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 li:not(:last-child) {
        margin-right: 12px;
    }
}

.pcheaderMenu01 .pcheaderList01 .contact01 a {
    padding-left: 30px;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 .contact01 a {
        padding-left: 20px;
    }
}

.pcheaderMenu01 .pcheaderList01 .contact01 a:before {
    content: "";
    width: 22px;
    height: 14px;
    background: url("") no-repeat;
    background-size: contain;
    position: absolute;
    top: 4px;
    left: 0;
}

@media screen and (max-width: 900px) {
    .pcheaderMenu01 .pcheaderList01 .contact01 a:before {
        width: 15px;
        height: 10px;
    }
}

.pcheaderMenu01 .pcheaderList01 a {
    color: #505050;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 8px;
    display: block;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .pcheaderMenu01 .pcheaderList01 a {
        font-size: 13px;
        padding-bottom: 4px;
    }
}

.pcheaderMenu01 .pcheaderList01 a:after {
    content: "";
    width: 0;
    height: 2px;
    background-color: #13ae9c;
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: 0.25s;
    transition: 0.25s;
}

.pcheaderMenu01 .pcheaderList01 a:hover {
    opacity: 1;
}

.pcheaderMenu01 .pcheaderList01 a:hover:after {
    width: 100%;
}

.pcheaderMenu01 .pcheaderList01 a.now {
    opacity: 1;
}

.pcheaderMenu01 .pcheaderList01 a.now:after {
    width: 100%;
}

.pcheaderMenu01 .pcheaderList01 .login01 {
    margin-top: -4px;
}

.spBtn01 {
    -webkit-transition: 1s;
    transition: 1s;
}

@media print,
screen and (min-width: 768px) {
    .spBtn01 {
        display: none !important;
    }
}

.spBtn01 p {
    width: 22px;
    height: 18px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    z-index: 9999;
    position: fixed;
    top: 13px;
    right: 15px;
}

.spBtn01 p.active span {
    background: #fff;
}

.spBtn01 p.active span:first-child {
    -webkit-transform: translateY(8px) rotate(-45deg);
    transform: translateY(8px) rotate(-45deg);
}

.spBtn01 p.active span:nth-child(2) {
    opacity: 0;
}

.spBtn01 p.active span:last-child {
    -webkit-transform: translateY(-8px) rotate(45deg);
    transform: translateY(-8px) rotate(45deg);
}

.spBtn01 span {
    width: 22px;
    height: 2px;
    margin: 0 auto;
    background: #14af9c;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
}

.spBtn01 span:first-child {
    top: 0;
}

.spBtn01 span:nth-child(2) {
    top: 8px;
}

.spBtn01 span:nth-child(3) {
    bottom: 0;
}

.spBtn01.active {
    width: 100%;
    height: 45px;
    background: rgba(19, 174, 156, 0.85);
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.globalMenuArea01 {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 45px;
    padding-bottom: 45px;
    background: rgba(19, 174, 156, 0.85);
    border: 0;
    overflow-y: scroll;
    z-index: 9998;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -ms-overflow-style: none;
}

.globalMenuArea01::-webkit-scrollbar {
    display: none;
}

@media print,
screen and (min-width: 768px) {
    .globalMenuArea01 {
        display: none !important;
    }
}

.globalMenuArea01 .menuContent01 {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.globalMenuArea01 .menuContent01.service {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.globalMenuArea01 .menuContent01.service .spMenu a {
    font-weight: bold;
    padding: 15px;
}

.globalMenuArea01 .spMenu a {
    padding: 20px;
}

.globalMenuArea01 a {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    display: block;
    position: relative;
}

.globalMenuArea01 .spLoginMenuArea01 {
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px;
}

.globalMenuArea01 .spLoginTitle {
    color: #13ae9c;
    font-size: 16px;
    padding-bottom: 6px;
    border-bottom: 2px solid #13ae9c;
}

.globalMenuArea01 .spLoginTitle img {
    margin-right: 5px;
}

.globalMenuArea01 .spLoginMenu {
    padding: 25px 0 10px;
}

.globalMenuArea01 .spLoginMenu li:first-child {
    margin-bottom: 20px;
}

.globalMenuArea01 .spLoginMenu a {
    color: #13ae9c;
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-weight: bold;
}

.globalMenuArea01 .contact01 a span {
    padding-left: 30px;
    position: relative;
}

.globalMenuArea01 .contact01 a span:after {
    content: "";
    width: 22px;
    height: 14px;
    margin: auto;
    background: url("") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.globalMenuArea01 .login01 {
    margin-top: -4px;
}

.globalMenuArea01 .login01 a span {
    background: url() no-repeat center left;
    background-size: 16px 16px;
    padding: 0 0 0 24px;
}

.globalMenuArea01 .dropBtn01 {
    color: #fff;
    padding: 15px;
    position: relative;
}

.globalMenuArea01 .dropBtn01:before,
.globalMenuArea01 .dropBtn01:after {
    content: "";
    position: absolute;
}

.globalMenuArea01 .dropBtn01:before {
    width: 14px;
    height: 1px;
    margin: auto 0;
    background: #fff;
    border: 0;
    -webkit-transform: none;
    transform: none;
    display: block;
    top: 0;
    right: 15px;
    bottom: 0;
    left: auto;
}

.globalMenuArea01 .dropBtn01:after {
    width: 1px;
    height: 14px;
    background: #fff;
    border: 0;
    -webkit-transform: none;
    transform: none;
    right: 22px;
}

.globalMenuArea01 .dropBtn01.active:after {
    display: none;
}

.globalMenuArea01 .drop01 ul {
    display: none;
}

.globalMenuArea01 .drop01 a {
    padding-left: 25px;
}

.loginMenuArea01 {
    position: fixed;
    background-color: #13ae9c;
    width: 100%;
    padding: 20px 0;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 10000;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    display: none;
}

@media screen and (max-width: 767px) {
    .loginMenuArea01 {
        top: 45px;
    }
}

.loginMenuArea01.scroll {
    top: 55px;
}

@media screen and (max-width: 767px) {
    .loginMenuArea01.scroll {
        top: 45px;
    }
}

.loginMenuArea01 .container {
    position: relative;
}

.loginMenuArea01 .container::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent #13ae9c transparent;
    display: block;
    position: absolute;
    top: -28px;
    right: 53px;
}

.loginMenu01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginMenu01 li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loginMenu01 li a {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.loginMenu01 li img {
    margin-right: 7px;
}

.loginMenu01 li:first-child::after {
    content: "";
    width: 1px;
    height: 50px;
    margin: 0 40px;
    background-color: #fff;
    display: block;
}

.menuArea01 {
    padding: 25px 0 50px;
    background-color: #13AE9C;
}

@media screen and (max-width: 767px) {
    .menuArea01 {
        padding: 25px 0;
    }
}

.menuContent01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.menuContent01 .menuList01 {
    margin-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .menuContent01 .menuList01 {
        margin-bottom: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.menuContent01 .menuList01 li {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

.menuContent01 .menuList01 li:not(:last-child) {
    margin-right: 35px;
}

@media screen and (max-width: 767px) {
    .menuContent01 .menuList01 li:not(:last-child) {
        margin: 0;
    }
}

.menuContent01 .menuList01 li:last-child a {
    padding-left: 30px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .menuContent01 .menuList01 li:last-child a {
        padding-left: 17px;
    }
}

.menuContent01 .menuList01 li:last-child a:before {
    content: "";
    width: 22px;
    height: 14px;
    margin: auto;
    background: url("") no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

@media screen and (max-width: 767px) {
    .menuContent01 .menuList01 li:last-child a:before {
        width: 13px;
        height: 8px;
    }
}

.menuContent01 .menuList01 li a {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

@media screen and (max-width: 767px) {
    .menuContent01 .menuList01 li a {
        font-size: 12px;
        padding: 8px;
    }
}

.menuContent01 .footer-logo {
    width: 71px;
}

@media screen and (max-width: 767px) {
    .menuContent01 .footer-logo {
        width: 44px;
    }
}

.menuContent01 .footer-logo img {
    vertical-align: bottom;
}

.footerArea01 {
    padding: 15px 0 20px;
}

@media screen and (max-width: 767px) {
    .footerArea01 {
        padding: 15px;
    }
}

.footerArea01 .footerContent01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerContent01 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.footerArea01 .footerBox01 {
    font-size: 13px;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerBox01 {
        font-size: 11px;
    }
}

.footerArea01 .footerBox01.box01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (max-width: 767px) {
    .footerArea01 .footerBox01.box01 {
        margin-bottom: 15px;
    }
}

.footerArea01 .footerBox01.box01 p {
    display: block;
}

.footerArea01 .footerBox01.box01 p:first-child {
    margin-right: 25px;
}

.footerLogo01 {
    width: 71px;
    height: 101px;
}

@media screen and (max-width: 767px) {
    .footerLogo01 {
        width: 44px;
        height: 61px;
    }
}

/* .container {
    width: 94%;
} */

.titleArea01 {
    margin-bottom: 35px;
}

@media screen and (max-width: 767px) {
    .titleArea01 {
        margin: 30px 0 20px;
    }
}

h2 {
    text-align: center;
    color: #727272;
    font-size: 35px;
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    h2 {
        font-size: 20px;
    }
}

.txt01,
.txt02 {
    text-align: center;
    color: #505050;
    font-size: 14px;
    line-height: 2;
}

@media screen and (max-width: 360px) {

    .txt01,
    .txt02 {
        font-size: 12px;
    }
}

.txt01 a,
.txt02 a {
    color: #13ae9c;
    font-weight: bold;
    border-bottom: 1px solid #505050;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .txt02 {
        text-align: left;
    }
}

.progressList01 {
    width: 370px;
    margin: 80px auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .progressList01 {
        width: 233px;
        margin: 40px auto;
    }
}

.progressList01 li {
    text-align: center;
    color: #a0a0a0;
    font-size: 14px;
    width: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.progressList01 li::before {
    content: "";
    width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #a0a0a0;
    border-radius: 50%;
    display: block;
}

.progressList01 li::after {
    content: "";
    width: 135px;
    height: 2px;
    background-color: #a0a0a0;
    display: block;
    position: absolute;
    top: 8px;
    left: 35px;
}

@media screen and (max-width: 767px) {
    .progressList01 li::after {
        width: 65px;
    }
}

.progressList01 li:last-child::after {
    display: none;
}

.progressList01 li.active {
    color: #13ae9c;
    font-size: 15px;
    font-weight: bold;
    border: 0px;
}

.progressList01 li.active::before {
    width: 20px;
    height: 20px;
    margin-top: 0;
    background-color: #13ae9c;
    border-radius: 50%;
    display: block;
}

.contactFormArea01 {
    max-width: 1000px;
    width: 94%;
    margin: 0 auto 120px;
    padding: 35px 4%;
    background-color: #fafafa;
}

@media screen and (max-width: 767px) {
    .contactFormArea01 {
        margin: 0 auto 40px;
        padding: 30px 4%;
    }
}

.contactTable01 {
    width: 100%;
}

.contactTable01 th {
    text-align: left;
    color: #505050;
    font-size: 18px;
    font-weight: bold;
    width: 25%;
    padding: 31px 20px 25px 0;
    vertical-align: top;
}

@media screen and (max-width: 1000px) {

    .contactTable01 th,
    .documentTh {
        width: 35%;
    }
}

@media screen and (max-width: 767px) {

    .contactTable01 th,
    .documentTh {
        margin-bottom: 10px;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
    }
}

.contactTable01 th::after {
    content: "";
    display: block;
    clear: both;
}

.contactTable01 th .require {
    color: #fff;
    font-size: 12px;
    width: 40px;
    height: 20px;
    margin-top: 4px;
    background-color: #e6281e;
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 40px;
}

@media all and (-ms-high-contrast: none) {
    .contactTable01 th .require {
        padding-top: 4px;
    }
}

@media screen and (max-width: 767px) {
    .contactTable01 th .require {
        float: none;
        margin-top: 0;
        margin-left: 10px;
    }
}

.contactTable01 td {
    width: 75%;
    padding: 25px 0;
}

@media screen and (max-width: 1000px) {
    .contactTable01 td {
        width: 65%;
    }
}

@media screen and (max-width: 767px) {
    .contactTable01 td {
        margin-bottom: 30px;
        padding: 0;
        display: block;
        width: 100%;
    }
}

.contactTable01 td .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactTable01 td .flex input {
    width: 87.5%;
}

@media screen and (max-width: 767px) {
    .contactTable01 td .flex input {
        width: 100%;
    }
}

.contactTable01.confirm td {
    font-size: 16px;
    padding: 34px 0 25px;
}

@media screen and (max-width: 767px) {
    .contactTable01.confirm td {
        padding: 0;
    }
}

.memo01 {
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0 5px 0;
}

@media screen and (max-width: 767px) {
    .memo01 {
        margin: 12px 0 8px;
    }
}

.memo02 {
    font-size: 16px;
    margin-left: 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@media screen and (max-width: 767px) {
    .memo02 {
        font-weight: normal;
        margin-left: auto;
    }
}

.alertBox {
    max-width: 740px;
    width: 90%;
    margin: 0 auto 40px;
    padding: 20px;
    background-color: #fff0f0;
    border: 2px solid #e6281e;
}

@media screen and (max-width: 767px) {
    .alertBox {
        padding: 10px;
        margin-bottom: 25px;
    }
}

.alertBox p {
    color: #e6281e;
    font-size: 16px;
    font-weight: bold;
}

.alert {
    padding: 3px;
    background-color: #fff0f0 !important;
    border: 1px solid #e6281e !important;
}

.alertTxt {
    color: #e6281e;
    font-size: 14px;
    margin-top: 7px;
}

.checkbox {
    margin: 15px 0 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .checkbox {
        margin: 0 0 25px;
    }
}

.centerBtn01 {
    margin: 35px auto 40px;
}

@media screen and (max-width: 767px) {
    .centerBtn01 {
        margin: 25px auto;
    }
}

.btnList01 {
    margin: 35px auto 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .btnList01 {
        width: 100%;
        margin: 25px auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.btnList01 li {
    width: 240px;
}


@media screen and (max-width: 767px) {
    .btnList01 li {
        width: 100%;
    }
}

.btnList01 li:first-child {
    margin-right: 40px;
}

@media screen and (max-width: 767px) {
    .btnList01 li:first-child {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.thanksArea01 {
    text-align: center;
}

.thanksArea01 .thanksTxt01 {
    font-size: 30px;
}

.thanksArea01 .btn01 {
    margin: 40px auto 0;
}

.btn {
    white-space: normal;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 10px 16px;
    font-size: 19px;
    line-height: 1.33333;
    border-radius: 0 !important;
}

.btn-danger {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
}

.header .pcheaderMenu01 .pcheaderList01 a {
    font-size: 15px;
}

@media screen and (max-width: 900px) {
    .header .pcheaderMenu01 .pcheaderList01 a {
        font-size: 13px;
    }
}

.header .pcheaderMenu01 .pcheaderList01 li:not(.login01) a {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
}

.globalMenuArea01 ul a {
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
}

main {
    display: block;
    /* IE対策に追加 */
}

.mainContent {
    padding-top: 160px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .mainContent {
        padding-top: 50px;
    }
}

/* .mainContent.subPage {
    padding-top: 160px;
} */

@media screen and (max-width: 767px) {
    .mainContent.subPage {
        padding-top: 50px;
    }
}

.lastArea01 {
    padding-bottom: 70px;
}

@media screen and (max-width: 767px) {
    .lastArea01 {
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 360px) {
    .lastArea01 {
        padding-bottom: 110px;
    }
}

.contactArea01 {
    background: #eee;
    display: none;
    position: relative;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 999;
}

.contactContent01 {
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .contactContent01 {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

@media screen and (max-width: 767px) {
    .contactContent01.container {
        width: 94%;
        padding: 10px 0;
    }
}

.contactContent01 li {
    width: 280px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .contactContent01 li {
        width: 48%;
    }
}

.contactContent01 .mailBtn01 {
    margin-right: 100px;
}

@media screen and (max-width: 1000px) {
    .contactContent01 .mailBtn01 {
        margin-right: 50px;
    }
}

@media screen and (max-width: 767px) {
    .contactContent01 .mailBtn01 {
        margin-right: 0;
    }
}

.contactContent01 .mailBtn01 a {
    font-weight: bold;
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid #727272;
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 767px) {
    .contactContent01 .mailBtn01 a {
        font-size: 12px;
        height: 30px;
    }
}

@media screen and (max-width: 360px) {
    .contactContent01 .mailBtn01 a {
        font-size: 11px;
    }
}

.contactContent01 .mailBtn01 a::before {
    content: "";
    width: 8px;
    height: 8px;
    margin: auto;
    border: 0px;
    border-top: solid 1px #727272;
    border-right: solid 1px #727272;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .contactContent01 .mailBtn01 a::before {
        width: 6px;
        height: 6px;
        right: 10px;
    }
}

.contactContent01 .mailBtn01 img {
    margin-right: 5px;
}

@media screen and (max-width: 767px) {
    .contactContent01 .mailBtn01 img {
        width: 16px;
    }
}

.contactContent01 .downloadBtn01 {
    background: transparent;
}

.contactContent01 .downloadBtn01 a {
    font-size: 16px;
    width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contactContent01 .downloadBtn01 a span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .contactContent01 .downloadBtn01 a {
        font-size: 12px;
        height: 30px;
    }
}

@media screen and (max-width: 360px) {
    .contactContent01 .downloadBtn01 a {
        font-size: 11px;
        padding-right: 16px;
    }
}

@media screen and (max-width: 767px) {
    .contactContent01 .downloadBtn01 a::before {
        width: 6px;
        height: 6px;
        right: 10px;
    }
}

.contactContent01 .downloadBtn01 img {
    margin-right: 5px;
}

@media screen and (max-width: 767px) {
    .contactContent01 .downloadBtn01 img {
        width: 16px;
    }
}

.pageTop {
    bottom: 90px;
    right: 30px;
}

@media screen and (max-width: 767px) {
    .pageTop {
        bottom: 60px;
        right: 15px;
    }
}

.pcheaderMenu01 .pcheaderList01 .login01 a, .pcheaderMenu01 .pcheaderList01 .login01 button {
    color: #13ae9c;
    height: 30px;
    border: 2px solid #13ae9c;
    border-radius: 15px;
    width: 100%;
    background-color: #fff;
}

.pcheaderMenu01 .pcheaderList01 .login01 a, .pcheaderMenu01 .pcheaderList01 .login01 button:hover {
    color: #fff;
    height: 30px;
    border: 2px solid #13ae9c;
    border-radius: 15px;
    width: 100%;
    background-color: #13ae9c;
}

.pcheaderMenu01 .pcheaderList01 .login01 a span, .pcheaderMenu01 .pcheaderList01 .login01 button span {
    background: url(../../../utils/image/ico_login01.png) no-repeat center left 15%;
    background-size: 16px 16px;
    padding: 0 24px 0 40px;
    background-color: initial;
}

.pcheaderMenu01 .pcheaderList01 .login01 a:hover span,
.pcheaderMenu01 .pcheaderList01 .login01 button:hover span {
  background: url(../../../utils/image/ico_login02.svg) no-repeat center left 15%;
  background-size: 16px 16px;
}

.btn01 {
    width: 300px;
    background-color: #fff;
  }
  @media screen and (max-width: 767px) {
    .btn01 {
      width: 275px;
    }
  }

.btn01 a,
.btn01 button {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 15px 11px;
  border-radius: 25px;
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  letter-spacing: 1px;
  overflow: hidden;
  border: 0px;
}
@media all and (-ms-high-contrast: none) {
  .btn01 a,
.btn01 button {
    padding: 15px 15px 8px;
  }
}
@media screen and (max-width: 767px) {
  .btn01 a,
.btn01 button {
    padding: 6.5px 10px;
  }
}
.btn01 a:after,
.btn01 button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.btn01 a:before,
.btn01 button:before {
  content: "";
  width: 8px;
  height: 8px;
  margin: auto;
  border: 0px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  z-index: 1;
}
.btn01 a span,
.btn01 button span {
  position: relative;
  z-index: 1;
}
.btn01 a:hover,
.btn01 button:hover {
  opacity: 1;
}
.btn01 a:hover:after,
.btn01 button:hover:after {
  opacity: 0;
}
@media all and (-ms-high-contrast: none) {
  .btn01 button {
    padding: 12px 15px 11px;
  }
}

.greenBtn01 a,
.greenBtn01 button {
  background: #56d592;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, color-stop(30%, #56d592), to(#63f2bb));
  background: linear-gradient(to right, #56d592 30%, #63f2bb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#56d592", endColorstr="#63f2bb",GradientType=1 );
  /* IE6-9 */
}
.greenBtn01 a:after,
.greenBtn01 button:after {
  background: #13ae9c;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, color-stop(30%, #13ae9c), to(#56d592));
  background: linear-gradient(to right, #13ae9c 30%, #56d592 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#13ae9c", endColorstr="#56d592",GradientType=1 );
  /* IE6-9 */
}