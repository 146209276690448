.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: left;
  }
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

.error-message {
  font-size: 12px;
  color: red;
}

.btn-default {
  color: #302CE1;
  background-color: white;
  border-color: #302CE1;
}

.btn-primary {
  color: #fff !important;
  background-color: #302CE1 !important;
  border-color: #302CE1 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #2f5fef !important;
  border-color: #2f5fef !important;
}

.btn {
  border-radius: 0px !important;
  font-size: 13px !important;
}

input.form-control {
  border: 1px solid #949494 !important;
  border-radius: 0px !important;
}

.require:after {
  content: "*";
  color: red;
}

*:before,
*:after {
  /* -webkit-box-sizing: border-box; */
  -moz-box-sizing: border-box;
  /* box-sizing: border-box; */}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px !important;
}

.row:before,
.row:after {
  content: " ";
  display: table
}

.row:after {
  clear: both
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed;
}

.caution-message {
  font-size: 14px;
  color: red;
}

.container {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.page-heading {
  padding-top: 20px;
}

.breadcrumb {
  padding: 0 0 !important;
  margin-bottom: 21px !important;
  list-style: none !important;
  border-radius: 0 !important;
}

.breadcrumb, ul > li a {
  color: #302ce1;
  padding: 2px 4px;
  text-decoration: none;
}

.breadcrumb, ul > li a:hover {
  color: #302ce1 !important;
}

.breadcrumb, ul > li,.active {
  color: #333;
}

.breadcrumb>li+li:before {
  content: "> ";
  padding: 0 5px;
  color: #666;
}

#content-container {
  min-height: calc(100vh - 131px) !important;
}

#content {
  padding: 20px 0px 0px 0px;
}

.wrapper-body {
  min-height: calc(100vh - 131px) !important;
}

